import './css/about.css';


import smallImg from './images/5.jpg';
import largeImg from './images/3.jpg';


function About(){
    return(
        <>
            <section  id="about-us" className='about'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-images'>
                                <div>
                                    <div className='text'>
                                        <i class="las la-user-shield"></i>
                                        <p> نضمن تقديم خدمات محترفة وموثوقة في كل مرة </p>
                                    </div>

                                    <img src={smallImg} alt="about-sm-img" />
                                </div>

                                <img src={largeImg} alt="about-lg-img" />
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-text'>
                                <h2>اتصل بنا اليوم للحصول على استشارة مجانية   </h2>
                                <h1> احجز معنا لتجربة فريدة من نوعها في نقل وشحن مركبتك. نحن هنا لخدمتك وضمان راحتك وسلامة ممتلكاتك </h1>
                                <div className='text'>
                                    <i class="las la-truck-pickup"></i>
                                    <p> نحن نعتمد على فريق من ذوي الخبرة والكفاءة في مجال نقل المركبات، ونضمن تقديم خدمات محترفة وموثوقة في كل مرة.</p>
                                </div>

                                <div className='contact'>
                                    <a href="https://wa.me/+966559121782">
                                        <i class="las la-arrow-right"></i>
                                        أرسل رسالة الان
                                    </a>

                                    <div>
                                        <i class="las la-phone-volume"></i>
                                        <a href="tel:+971523653394">
                                            +971 523 653 394
                                        </a>
                                        
                                        <a href="tel:+966559121782">
                                            +966 559 121 782
                                        </a>

                                        <a href="tel:+966558281857">
                                            +966 558 281 857
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default About;