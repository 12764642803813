import './css/footer.css';
import Logo from './images/logo.png';

function Footer(){
    return(
        <>
            <footer>
                <div className='container-fluid'>
                    <div className='row'>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <div className='logo'>
                                    <img  loading='lazy' src={Logo} alt="logo" />
                                </div>

                                <p> إذا كان لديك أي استفسار أو تحتاج إلى مزيد من المعلومات حول خدماتنا، فلا تتردد في الاتصال بنا. نحن هنا لخدمتك والرد على جميع استفساراتك. يمكنك التواصل معنا عبر الوسائل التالية:</p>

                                <h2> تجدنا في الأوقات التالية </h2>
                                <h3> Sun-Thu : 08:00 Am to 6:00 Pm </h3>

                                <div className='social'>
                                    <a href="#">
                                        <i class="lab la-facebook-f"></i>
                                    </a>

                                    <a href="#">
                                        <i class="lab la-instagram"></i>
                                    </a>

                                    <a href="#">
                                        <i class="lab la-twitter"></i>
                                    </a>

                                    <a href="#">
                                        <i class="lab la-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>




                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> روابط مختصرة </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                <ul>
                                    <li>
                                        <a href="#home">
                                            الرئيسية
                                        </a>
                                    </li>


                                    <li>
                                        <a href="#about-us">
                                            عن الشركة 
                                        </a>
                                    </li>


                                    <li>
                                        <a href="#contact-us">
                                            تواصل معنا
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> وسائل التواصل </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                    <div className='contact-info'>

                                    <div className='info'>
                                        <h4> رقم الهاتف </h4>
                                        <a href="tel:+971523653394"> <i class="las la-phone"></i> +971 523 653 394 </a>
                                        <a href="tel:+966559121782"> <i class="las la-phone"></i> +966 559 121 782 </a>
                                        <a href="tel:+966558281857">
                                        <i class="las la-phone"></i>
                                            +966 558 281 857
                                        </a>
                                    </div>

                                    <div className='info'>
                                        <h4> الموقع</h4>
                                        <a href="https://maps.app.goo.gl/w42xFyEko9LHoc368"> <i class="las la-map-marker"></i>  دبي والسعودية </a>
                                    </div>
                                </div>


                            </div>

                            
                        </div>



                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> استخدم واتساب </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                <p>
                                نحن نتطلع إلى سماع أخبارك وخدمتك بأفضل طريقة ممكن. شكرًا لاهتمامك بشركة النور لنقل وقطر المركبات.
                                </p>

                                <a href="https://wa.me/+966559121782"> 
                                    راسلنا الان <i class="las la-arrow-right"></i>
                                </a>


                            </div>

                        </div>







                    </div>
                </div>

                <div className='copy-right'>
                    <h1>  &copy; 2023   <span> النور  </span> جميع الحقوق محفوظة </h1>
                </div>
            </footer>
        </>
    )
}

export default Footer;