import './css/benefits.css';

import benefitImg1 from './images/4.jpg';
import benefitImg2 from './images/6.jpg';
import benefitImg3 from './images/7.jpg';



function Benefits(){
    return(
        <>
            <section className='benefits'>
                <div className='container-fluid'>
                    <div className='benefits-header'>

                        <div className='text'>
                            <h3> نحن هنا لخدمتك وضمان راحتك وسلامة ممتلكاتك </h3>
                            <h1> نعتبر كل مركبة مسؤوليتنا، ونسعى دائمًا لضمان وصولها بأمان </h1>
                            <p> في شركة النور لنقل وقطر المركبات، نعمل بجد لتحقيق رضاك التام وتقديم أفضل تجربة في نقل وشحن مركبتك. اعتمد علينا لضمان سلامة ممتلكاتك وراحتك</p>
                        </div>

                        <div className='contact-info'>
                            <a href="https://wa.me/+966559121782">
                                ارسل رسالة
                            </a>
                            <a href="tel:+971523653394">
                                اتصل بنا
                            </a>
                        </div>


                    </div>

                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                            <div className='benefit'>
                                <img src={benefitImg1} alt="benefit-image" />
                                <div className='text'>
                                    <i class="las la-award"></i>
                                    <h2> خدمة شحن متميزة </h2>
                                    <p>  نستخدم أحدث وسائل النقل والسطحات ذات النزول الكامل المغلقة والمقفلة لضمان سلامة وحماية مركبتك طوال رحلتها</p>
                                    <a href="tel:+971523653394"><i class="las la-arrow-left"></i> إكتشف المزيد  </a>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                            <div className='benefit'>
                            <img src={benefitImg2} alt="benefit-image" />
                                <div className='text'>
                                <i class="las la-list-ul"></i>
                                    <h2> تنوع في الخدمات </h2>
                                    <p> نقدم سطحات نزول كامل هيدروليك وسطحات نزول عادي هيدروليك لتلبية مختلف احتياجات النقل </p>
                                    <a href="tel:+971523653394"><i class="las la-arrow-left"></i> إكتشف المزيد  </a>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                            <div className='benefit'>
                            <img src={benefitImg3} alt="benefit-image" />
                                <div className='text'>
                                    <i class="las la-user-shield"></i>
                                    <h2> تأمين الحمولة </h2>
                                    <p> نهتم بسلامة مركبتك، ولذلك نقدم تأمينًا شاملاً على جميع السيارات التي ننقلها </p>
                                    <a href="tel:+971523653394"><i class="las la-arrow-left"></i> إكتشف المزيد  </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Benefits;