import './css/landing.css';

function Landing(){
    return(
        <>
            <section id="home" className='landing'>
                <div className='overlay'></div>
                <div className='container-fluid'>
                    <div className='landing-content'>
                        <h3> نسعى جاهدين لتلبية احتياجات عملائنا في مجال نقل وشحن المركبات </h3>
                        <h1> شركة النور لنقل وقطر المركبات! نحن نفخر بتقديم خدمات شحن ونقل المركبات من الإمارات إلى مجلس التعاون الخليجي والعكس بأعلى معايير الجودة والموثوقية </h1>
                        <p>  سواء كانت سيارات أو دراجات نارية، بأكبر قدر من الاحترافية والعناية. نعتبر كل مركبة مسؤوليتنا، ونسعى دائمًا لضمان وصولها بأمان وفي الحالة المثلى </p>

                        <div>
                            <i class="las la-truck-pickup"></i>
                            <i class="las la-truck"></i>
                            <i class="las la-car-alt"></i>
                            <a href="tel:+966559121782">
                                تواصل معنا الان
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </> 
    )
}

export default Landing;