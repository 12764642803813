import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import Benefits from './components/Benefits';
import Chooseus from './components/Chooseus';
import Contact from './components/Contact';
import Footer from './components/Footer';


function App() {

return (
    <div className="App">
            <Routes>
            

            <Route path="/" element={
                    <>
                        <Navbar />
                        <Landing />
                        <About />
                        <Benefits />
                        <Chooseus/>
                        <Contact />
                        <Footer />
                    </>
                } />



        </Routes>

        
    </div>
);
}

export default App;
