import './css/navbar.css';
import Logo from './images/logo.png';

import { useState,useEffect,useRef  } from "react";
import $ from 'jquery';


function Navbar(){




    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $("nav").addClass("active");

            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
                $("nav").removeClass("active");

            });
        }
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);




    return(
        <>
            <nav>
                <div className='container-fluid'>
                    <div className='nav-content'>
                        <div className='logo'>
                            <img src={Logo} alt="elnoor logo" />
                            <h2>النور لنقل وقطر المركبات  </h2>
                        </div>

                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                            <div className="nav-elements">
                        <button id="close-nav"> <i className="las la-times"></i> </button>
                            <ul>
                                <li>
                                    <a href="#home">
                                        الرئيسية
                                    </a>
                                </li>


                                <li>
                                    <a href="#about-us">
                                        عن الشركة 
                                    </a>
                                </li>


                                <li>
                                    <a href="#contact-us">
                                        تواصل معنا
                                    </a>
                                </li>


                            </ul>

                            <div className='nav-contact-info'>
                                <a href="https://wa.me/+966559121782">
                                    راسلنا عبر واتساب <i class="lab la-whatsapp"></i>
                                </a>

                                <div>
                                    <i class="las la-phone-volume"></i>
                                    <div className='text'>
                                        <h3> اتصل بنا هاتفيا </h3>
                                        <a href="tel:+971523653394">
                                            +971 523 653 394
                                        </a>
                                        <a href="tel:+966559121782">
                                            +966 559 121 782
                                        </a>

                                        <a href="tel:+966558281857">
                                            +966 558 281 857
                                        </a>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;