import './css/chooseus.css';

import carImg from './images/car.webp';

function Chooseus(){
    return(
        <>
            <section className='choose-us'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='choose-us-img'>
                                <img src={carImg} alt="car img" />
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='choose-us-text'>
                                <p> في شركة النور لنقل وقطر المركبات، نعمل بجد لتحقيق رضاك التام وتقديم أفضل تجربة في نقل وشحن مركبتك. اعتمد علينا لضمان سلامة ممتلكاتك وراحتك.</p>
                                <ul>
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        سلامة مركبتك
                                    </li>

                                    <li>
                                        <i class="las la-check-circle"></i>
                                        مرونة الخدمات
                                    </li>


                                    <li>
                                        <i class="las la-check-circle"></i>
                                        تأمين الحمولة
                                    </li>

                                    
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        فريق ذو خبرة
                                    </li>


                                    <li>
                                        <i class="las la-check-circle"></i>
                                        توصيل في الوقت المحدد
                                    </li>


                                    <li>
                                        <i class="las la-check-circle"></i>
                                        توفير الوقت والجهد
                                    </li>

                                    <li>
                                        <i class="las la-check-circle"></i>
                                        خدمة عملاء استثنائية
                                    </li>



                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Chooseus;